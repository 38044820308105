export const lerp = (start, end, amt) => {
    return (1-amt)*start+amt*end
};

export const clamp = (num, min, max) => {
    return num <= min ? min : num >= max ? max : num;
  }
  


  export const hasAttrInTree = (element, attr) => {
    do {
      if (element.classList && element.hasAttribute(attr)) {
        return true;
      }
      element = element.parentNode;
    } while (element);
    return false;
  }

  export const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  export const isTouchDevice = () => {

    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
  };
import React, { Component } from 'react';
import {clamp, isTouchDevice} from '../utils';
import MobileDetect from 'mobile-detect';

if(typeof(window) !== 'undefined') {
    var PIXI = require('pixi.js');
    var Filters = require('pixi-filters');

    var RGBSplitFilter = Filters.RGBSplitFilter;
    var BulgePinchFilter = Filters.BulgePinchFilter;
    var OldFilmFilter = Filters.OldFilmFilter;
}

class PixiCanvas extends Component {
    
    constructor() {
        
        super();
        
        this.scrollY = 0;
        this.glImages = [];
    }
    
    componentDidMount() {
        
        
        var md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile() || isTouchDevice()) return;
        
        this.init();
        
        window.addEventListener("addImageToCanvas", this.addImage);
        window.addEventListener("onUpdateResize", this.onUpdateResize);
        window.addEventListener("onUpdate", this.onUpdate);

        window.addEventListener("linkListEnter", this.linkListEnter);
        window.addEventListener("linkListLeave", this.linkListLeave);
    }


    init = () => {

        this.app = new PIXI.Application({ 
            antialias: true,    // default: false
            transparent: true, // default: false
            resolution: 1,       // default: 1
            view: this.refs.canvas,
        });
    
    
        this.app.ticker = false;
        this.app.sharedTicker = false;
            
        this.app.renderer.autoResize = true;
        
        this.container = new PIXI.Container();

        this.bulge = new BulgePinchFilter();

        this.graphics = new PIXI.Graphics();
        
        this.noise = new OldFilmFilter({
            sepia:0,
            noise: 0.1,
            noiseSize:1,
            scratch:0,
            scratchDensity: 0,
            vignetting:0
        });

        this.container.filters = [this.bulge,this.noise];
  
        this.app.stage.addChild(this.container);

        this.app.renderer.render(this.app.stage);    
    }


    getRatio = (mode, canvasWidth,canvasHeight,imageWidth,imageHeight) => {

        const canvasAspect = canvasHeight/canvasWidth;
        const imageAspect =  imageHeight/imageWidth;
        
        let scaleX;
        let scaleY;
        
        switch (mode) {
            case 'fitH':
            scaleY = 1;
            scaleX = canvasAspect/imageAspect;
            break;
            case 'fitV':
            scaleX = 1;
            scaleY = imageAspect/canvasAspect;
            break;
            case 'contain':
            scaleY = 1;
            scaleX = canvasAspect/imageAspect;
            if (scaleX > 1) {
                scaleY = 1 / scaleX;
                scaleX = 1;
            }
            break;
            case 'cover':
            scaleY = 1;
            scaleX =  canvasAspect/imageAspect;
            if (scaleX < 1) {
              scaleY = 1 / scaleX;
              scaleX = 1;
            }
            break;
        }

        return {
            scaleY: scaleY,
            scaleX: scaleX
        }
    }

    setImageSize(elm,texture) {
        var bounds = elm.getBoundingClientRect();
    
        var crop = 100;
        var cropX = crop * (texture.width/texture.height);
        var cropY = crop;
        var ratios = this.getRatio('cover', bounds.width, bounds.height, texture.width, texture.height);

        var textureWidthScaled = bounds.width * ratios.scaleX;
        var textureHeightScaled = bounds.height * ratios.scaleY;

        var textureWidthScaledUp = textureWidthScaled + cropX;
        var textureHeightScaledUp = textureHeightScaled + cropY;

        var centerX = (textureWidthScaledUp - bounds.width);
        var centerY = (textureHeightScaledUp - bounds.height);
        texture.y = bounds.y - centerY/2 + this.scrollY;
        texture.x = bounds.x - centerX/2;
        
        texture.width = textureWidthScaledUp;
        texture.height = textureHeightScaledUp;
    }

    createMask(elm) {
        var bounds = elm.getBoundingClientRect();

        const mask = new PIXI.Graphics();
    
        mask.beginFill(0xDE3249);
        mask.drawRect( bounds.x,  bounds.y + this.scrollY, bounds.width, bounds.height);
        mask.endFill();

        return mask;
    }

    updateMask(elm, mask) {

        mask.clear();

        var bounds = elm.getBoundingClientRect();
        mask.beginFill(0xDE3249);
        mask.drawRect( bounds.x,  bounds.y + this.scrollY, bounds.width, bounds.height);
        mask.endFill();
    }


    addImage = (image) => {

        image = image.detail;
        
        var bounds = image.elm.getBoundingClientRect();

        const loader = new PIXI.Loader();
        
        loader
        .add(image.src)
        .load(() => {

            const frame = new PIXI.Container();
            
            // Mask
            const mask = this.createMask(image.elm);
            frame.addChild(mask);
            frame.mask = mask;

            // Image    
            var texture = PIXI.Sprite.from(image.src);   

            frame.addChild(texture);


            this.setImageSize(image.elm, texture);

            // Filters
            var rgb = new RGBSplitFilter();
            rgb.red =  new PIXI.Point(0, 0);
            rgb.green =  new PIXI.Point(0,0);
            rgb.blue =  new PIXI.Point(0,0);

            texture.filters = [rgb];

            this.container.addChild(frame);

            this.glImages.push({
                texture: texture,
                frame: frame,
                bounds:bounds,
                rgb: rgb,
                elm: image.elm
            });
        });
    }

    
    onUpdate = (e) => {
        const { currentScroll, velocity, mouse } = e.detail;
        this.scrollY = currentScroll; 
        
        this.app.stage.y = -this.scrollY; 
    
        this.glImages.forEach(glImage => {
            glImage.rgb.red =  new PIXI.Point(0, clamp(velocity*150, -50, 50) * -1);
            glImage.rgb.green =  new PIXI.Point(0, 0);
            glImage.rgb.blue =  new PIXI.Point(0,0);
        });
        
        this.bulge.center = [mouse.currentMouseX/this.width,mouse.currentMouseY/this.height];
        this.bulge.strength = clamp(0.2 + velocity*0.5 * -1, -0.5, 0.5);

        this.noise.seed = Math.random();
    
        this.app.renderer.render(this.app.stage);
    }

    onUpdateResize = (e) => {
        
        const {width,height} = e.detail;

        this.width = width;
        this.height = height;

        if(this.app) {
            this.app.renderer.resize(this.width, this.height);
        }
        
        if(this.container) {
            this.container.filterArea = new PIXI.Rectangle(0,0, this.width, this.height);
        }
        if(this.bulge) {
            this.bulge.radius = this.width/4;
        }


        if(this.glImages) {
        
            this.glImages.forEach(glImage => {
                this.setImageSize(glImage.elm, glImage.texture);
                this.updateMask(glImage.elm,glImage.frame.mask)
            });
        }

    }

    render() {
        return (
            <canvas ref="canvas" className="fixed top-0 left-0 pointer-events-none" />
        );
    }
}

export default PixiCanvas;
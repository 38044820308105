import React, { Component } from 'react';
import {hasAttrInTree,isTouchDevice} from '../utils';
import MobileDetect from 'mobile-detect';

class Cursor extends Component {

    componentDidMount() {

        var md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile() || isTouchDevice()) return;
            window.addEventListener("onUpdate", this.onUpdate);
    }

    onUpdate = (e) => {
        const { mouse } = e.detail;
        this.refs.cursor.style.transform = `translate3d(${mouse.currentMouseX}px, ${mouse.currentMouseY}px, 0)`;

        if(mouse.element) {
            if(hasAttrInTree(mouse.element, 'href')) {
                this.refs.cursor.classList.add('cursor--link');
            } else {
                this.refs.cursor.classList.remove('cursor--link');
            }
    
            if(hasAttrInTree(mouse.element, 'src')) {
                this.refs.cursor.classList.add('cursor--img');
            } else {
                this.refs.cursor.classList.remove('cursor--img');
            }
        }
        
    }

    render() {
        return (
            <div ref="cursor" className="cursor">
                <div className="cursor__ring">
                    <div className="cursor__dot" ref="dot"></div>
                </div>
            </div>
        );
    }
}

export default Cursor;
import React, { Component } from 'react';
import { delay } from '../utils';

class Loader extends Component {


      
    //   delay(3000).then(() => alert('runs after 3 seconds'));
    componentDidMount() {

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                delay(2000).then(() => this.ended());
            });
        })
    }

    ended() {
        document.body.classList.add('load-ended');

        delay(1350).then(() => this.complete());
        
    }

    complete() {
        document.body.classList.add('load-compelete');
        window.dispatchEvent(new CustomEvent("siteLoadDone"));
    }

    render() {
        return (
            <div className="loading fixed top-0 left-0 w-full h-full bg-black z-50">
                <p className="absolute ml-8 mb-8 bottom-0 left-0 text-xl lg:text-3xl leading-sub font-light uppercase tracking-tight text-white overflow-hidden">
                    <span className="loading__word">Hello</span>
                </p>
                <p className="absolute mr-8 mt-8 top-0 right-0 text-xl lg:text-3xl leading-sub font-light uppercase tracking-tight text-white overflow-hidden">
                    <span className="loading__word">World</span>
                </p>
            </div>
        );
    }
}

export default Loader;
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="mt-8">
      <div className="flex justify-between wrapper z-10">
        <Link to="/"><span className="w-4 h-4 bg-black block rounded-full"></span></Link>
        <a href="mailto:hello@jacobfrederiksen.com" className="text-xs lg:text-base uppercase">Get in touch</a>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React, { Component } from 'react';
import { lerp, delay, isTouchDevice } from '../utils';
import MobileDetect from 'mobile-detect';
var addWheelListener = require('wheel').addWheelListener;


class ScrollWrapper extends Component {
    
    constructor() {
        super();

        this.onWheel = this.onWheel.bind(this);
        this.update = this.update.bind(this);
        this.resize = this.resize.bind(this);
        this.mousemove = this.mousemove.bind(this);

        this.targetScroll = 0;
        this.currentScroll = 0;
        this.deltaY = 0;
        this.acceleration = 0;
        this.velocity = 0;

        this.targetMouseX = 0;
        this.currentMouseX = 0;
        this.targetMouseY = 0;
        this.currentMouseY = 0;
        this.accelerationMouseX = 0;
        this.accelerationMouseY = 0;
        this.velocityMouseX = 0;
        this.velocityMouseY = 0;

        this.scrollMax = 99999;

        this.siteIsLoaded = false;
    }

    componentDidMount() {
        
        var md = new MobileDetect(window.navigator.userAgent);

        if(md.mobile() || isTouchDevice()) return;

        window.addEventListener('resize', this.resize, {passive: true});
        this.resize();

        window.addEventListener("onUpdate", this.onUpdate);
        
        addWheelListener(window, this.onWheel);

        window.addEventListener('mousemove', this.mousemove, {passive: true});

        this.update();


        window.addEventListener("siteLoadDone", () => {
            this.siteIsLoaded = true;
        });
    }

    resize() {

        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        
        this.getScrollHeight();
        delay(1000).then(() => this.getScrollHeight());

    
        window.dispatchEvent(new CustomEvent("onUpdateResize", {
            detail: {
                width: this.windowWidth, 
                height: this.windowHeight,
            }
        }));
    }

    getScrollHeight() {
        this.scrollMax = this.refs.root.scrollHeight -  this.windowHeight;
    }

    mousemove(e) {
        this.targetMouseX = e.pageX;
        this.targetMouseY = e.pageY;
    }

    update() {

        requestAnimationFrame(() => {

            this.update();

            //Scroll
            this.currentScroll = lerp(this.currentScroll, this.targetScroll,0.11);
            
            this.acceleration = (this.currentScroll - this.targetScroll) / 1280;
            this.velocity =+ this.acceleration;
           
            this.refs.root.style.transform = `translateY(${this.currentScroll * -1}px)`;


            //Mouse
            this.currentMouseX = lerp(this.currentMouseX, this.targetMouseX,0.11);
            this.currentMouseY = lerp(this.currentMouseY, this.targetMouseY,0.11);
            
            this.accelerationMouseX = (this.currentMouseX - this.targetMouseX) / 1280;
            this.accelerationMouseY = (this.currentMouseY - this.targetMouseY) / 1280;
            this.velocityMouseX =+ this.accelerationMouseX;
            this.velocityMouseY =+ this.accelerationMouseY;
            
            
            window.dispatchEvent(new CustomEvent("onUpdate", {
                detail: {
                    currentScroll: this.currentScroll,
                    velocity: this.velocity,
                    mouse: {
                        currentMouseX: this.currentMouseX,
                        currentMouseY: this.currentMouseY,
                        accelerationMouseX: this.accelerationMouseX,
                        accelerationMouseY: this.accelerationMouseY,
                        velocityMouseX: this.velocityMouseX,
                        velocityMouseY: this.velocityMouseY,
                        element: document.elementFromPoint(this.currentMouseX,this.currentMouseY)

                    },
                    screen: {
                        width: this.windowWidth, 
                        height: this.windowHeight,
                    }
                }
            }));
            
        });
    }

    onWheel(e) {

        if(!this.siteIsLoaded) return;

        this.targetScroll = this.targetScroll + e.deltaY;

        if(this.targetScroll <= 0) {
            this.targetScroll = 0;
        }
        
        if(this.targetScroll >= this.scrollMax) {
            this.targetScroll = this.scrollMax;
        }

        return false;
    }

    render() {
        return (
            <div ref="root" className="scrollWrapper">
                {this.props.children}
            </div>
        );
    }
}

export default ScrollWrapper;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {Component} from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import ScrollWrapper from './partials/scrollWrapper';
import PixiCanvas from './partials/pixiCanvas';
import Cursor from './partials/cursor';
import Loading from './partials/loading';
import MobileDetect from 'mobile-detect';
import { isTouchDevice } from './utils';

import "./main.scss"

class Layout extends Component {

  componentDidMount() {

    requestAnimationFrame(() => {
      var md = new MobileDetect(window.navigator.userAgent);
      
      if(md.phone() || isTouchDevice() ) {
        this.addClassToHtml('mobile');  
      }
      else if(md.tablet()) {
        this.addClassToHtml('tablet');  
      } else {
        this.addClassToHtml('desktop');  
      }
    });

  }

  addClassToHtml(className) {
    document.querySelector('html').classList.add(className);  
  }

  render() {
    
      return (
        <StaticQuery
          query={graphql`
          query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
          }`} render={data => (

          <>
            <Loading />
            <main>
              <PixiCanvas />
              <ScrollWrapper>
                <>
                <Header siteTitle={data.site.siteMetadata.title} />
                
                {this.props.children}
              
                <div className="text-xs lg:text-base lg:flex justify-between mb-4 lg:mb-8 lg:mt-64 wrapper uppercase z-10">
                  <Link to="/">Jacob Frederiksen</Link>
        
                  <p className="lg:ml-10"><a href="mailto:hello@jacobfrederiksen.com" target="blank">Mail</a> • <a href="https://twitter.com/jacobfredsen" target="blank">Twitter</a> • <a href="https://www.linkedin.com/in/jacobfredsen/" target="blank">Linkedin</a></p>
        
                  <a href="mailto:hello@jacobfrederiksen.com" target="blank">{new Date().getFullYear()} All rights reserved</a>
                </div>
                </>
              </ScrollWrapper>
            </main>
            <Cursor />
          </>

        )}>
        </StaticQuery>
      )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;